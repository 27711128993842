import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import Mensajes from "./pages/pages-users/Mensajes";
import Form from "./pages/pages-users/login/Form";
import ForgotPassword from "./pages/pages-users/login/ForgotPassword";
import ResetPassword from "./pages/pages-users/login/ResetPassword";
import Ticket from "./pages/pages-users/Ticket";

import LayoutAdmin from "./pages/pages-admin/LayoutAdmin";
import LoginAdmin from "./pages/pages-admin/Login";
import Dashboard from "./pages/pages-admin/Dashboard";
import EventoAdmin from "./pages/pages-admin/Evento";
import ListaEventosAdmin from "./pages/pages-admin/Eventos";
import Participantes from "./pages/pages-admin/Participantes";
import ParticipantesDetails from "./pages/pages-admin/ParticipanteDetails";
import Talleres from "./pages/pages-admin/Talleres";
import PerfilAdmin from "./pages/pages-admin/Perfil";
import Reportes from "./pages/pages-admin/Reportes";
import InvitacionExito from "./pages/pages-admin/InvitacionExito";
import Login from "./pages/pages-users/login/Login";
import Error404 from "./pages/pages-common/Error404";
import "./assets/styles/App.css";
import "./assets/styles/argon-dashboard.min.css";

const App = () => {
  const location = useLocation();
  return (
    <Routes>
      <Route path="/" element={<Ticket />} />
      <Route path="/login" index element={<Login />} />
      <Route path="/Form" element={<Form />} />
      <Route path="/ForgotPassword" element={<ForgotPassword />} />
      <Route path="/ResetPassword/:token" element={<ResetPassword />} />

      <Route path="admin/login" element={<LoginAdmin />} />

      <Route
        path="/admin"
        element={<LayoutAdmin selectedPath={location.pathname.split("/")[2]} />}
      >
        <Route path="dashboard" index element={<Dashboard />} />
        <Route
          path="/admin"
          element={<Navigate to="/admin/participantes" replace />}
        />
        <Route path="evento/:id" element={<EventoAdmin />} />
        <Route path="eventos" element={<ListaEventosAdmin />} />
        <Route path="participantes" element={<Participantes />} />
        <Route
          path="participantes/:id_participante"
          element={<ParticipantesDetails />}
        />
        <Route path="talleres" element={<Talleres />} />
        <Route path="reportes" element={<Reportes />} />
        <Route path="perfil" element={<PerfilAdmin />} />
        <Route path="successInvitation/:code" element={<InvitacionExito />} />

        <Route path="mensajes" element={<Mensajes />} />
      </Route>

      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

export default App;
