import React from "react";
import { format } from "date-fns";
import { es } from "date-fns/locale";

const TallerComponent = ({ taller, user }) => {
  return (
    <div>
      {user !== "admin" && (
        <h5 className="ms-3">
          {format(
            new Date(taller.fecha_inicio_taller.split(" ")[0]),
            "d 'de' MMMM",
            {
              locale: es,
            }
          )}
        </h5>
      )}
      <div className={`card ${user === "admin" ? "border-0" : ""}`}>
        <div className={`card-body ${user === "admin" ? "border-0" : ""}`}>
          <div className="row">
            <div className="col-12 col-md-3 order-2 order-md-1">
              <div className="bg-light ps-3 pe-3 py-2">
                <p className="mb-2">
                  <i className="fa-solid fa-clock me-2"></i>
                  {user === "admin"
                    ? taller.hora_inicio
                    : taller.hora_inicio_taller}
                </p>
                <p className="mb-2">
                  <i className="fa-regular fa-clock me-2"></i>
                  {user === "admin" ? taller.hora_fin : taller.hora_fin_taller}
                </p>
                <p className="mb-2">
                  <i className="fa-solid fa-location-dot me-2"></i>
                  {user === "admin"
                    ? taller.tipo_modalidad
                    : taller.tipo_modalidad_taller}
                </p>
                <p className="mb-2">
                  <i className="fa-solid fa-location-arrow me-2"></i>
                  {user === "admin"
                    ? taller.ubicacion
                    : taller.ubicacion_taller}
                </p>
              </div>
            </div>
            <div className="col-12 col-md-9 order-1 order-md-2">
              <div style={{ width: "fit-content" }}>
                <h6 className="bg-gradient-primary text-white  px-2 text-center fw-light">
                  TALLER
                </h6>
              </div>
              <h3 className="my-auto">
                {user === "admin" ? taller.nombre : taller.nombre_taller}
              </h3>
              <p className="mb-0 mt-auto">
                {user === "admin"
                  ? taller.descripcion
                  : taller.descripcion_taller}
              </p>
            </div>
          </div>
        </div>
      </div>

      <br />
    </div>
  );
};

export default TallerComponent;
