import React from "react";

const Step1 = ({ handleChange, nextStep, formValues }) => {
  const handleButtonClick = (sexo) => {
    handleChange({ target: { value: sexo } }, "sexo");
  };

  return (
    <>
      <h6>
        <i className="fas fa-user me-2"></i>Personal
      </h6>
      <div className="form-group mb-1">
        <label htmlFor="" className="mb-0">
          * Nombre completo <small>(Como aparecerá en tu constancia)</small>
        </label>
        <input
          type="text"
          required
          className="form-control"
          name="nombre"
          placeholder="Nombre completo "
          aria-label="Nombre"
          onChange={(e) => handleChange(e, `nombre`)}
          value={formValues.nombre || ""}
        />
      </div>
      <div className="form-group mb-1">
        <label htmlFor="" className="mb-0">
          * Correo electrónico
        </label>
        <input
          type="email"
          required
          className="form-control"
          name="correoElectronico"
          placeholder="Correo electrónico"
          aria-label="Correo electrónico"
          value={formValues.correoElectronico || ""}
          onChange={(e) => handleChange(e, `correoElectronico`)}
        />
      </div>
      <div className="form-group mb-1">
        <label htmlFor="" className="mb-0">
          * Contraseña
        </label>
        <input
          type="password"
          required
          className="form-control"
          name="password"
          placeholder="Contraseña"
          aria-label="Contraseña"
          value={formValues.password || ""}
          onChange={(e) => handleChange(e, `password`)}
        />
      </div>
      <div className="form-group mb-1">
        <label htmlFor="" className="mb-0">
          * Repita contraseña
        </label>
        <input
          type="password"
          required
          className="form-control"
          name="repassword"
          placeholder="Repita contraseña"
          aria-label="Contraseña"
          value={formValues.repassword || ""}
          onChange={(e) => handleChange(e, `repassword`)}
        />
      </div>
      <div className="form-group mb-1">
        <label htmlFor="" className="mb-0">
          * Sexo
        </label>
        <div className="btn-group" role="group" aria-label="Seleccionar Sexo">
          <button
            type="button"
            className={`btn btn-outline-light btn-sexo text-xs text-dark ${
              formValues.sexo === "Masculino" ? "active" : ""
            }`}
            onClick={() => handleButtonClick("Masculino")}
          >
            Masculino
          </button>
          <button
            type="button"
            className={`btn btn-outline-light btn-sexo text-xs text-dark ${
              formValues.sexo === "Femenino" ? "active" : ""
            }`}
            onClick={() => handleButtonClick("Femenino")}
          >
            Femenino
          </button>
          <button
            type="button"
            className={`btn btn-outline-light btn-sexo text-xs text-dark ${
              formValues.sexo === "Prefiero no especificar" ? "active" : ""
            }`}
            onClick={() => handleButtonClick("Prefiero no especificar")}
          >
            Prefiero no especificar
          </button>
        </div>
        <input
          type="hidden"
          name="sexo"
          value={formValues.sexo}
          onChange={(e) => handleChange(e, `sexo`)}
        />
      </div>

      <div className="form-group mb-1 ms-auto text-end mt-3">
        <button
          type="button"
          className="btn btn-secondary bg-gradient-secondary"
          onClick={nextStep}
        >
          Siguiente
        </button>
      </div>
    </>
  );
};

export default Step1;
