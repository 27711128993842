import imageUrl from "../../assets/images/fondo.webp";

const AdminBackgroundColor = () => {
  const styleBackground = {
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: "cover",
    minHeight: "300px",
    position: "absolute",
    width: "100%",
  };

  return (
    <div className="">
      <div
        className="min-height-300 bg-primary position-absolute w-100"
        style={styleBackground}
      ></div>
    </div>
  );
};

export default AdminBackgroundColor;
