import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { es } from "date-fns/locale";

const Step4 = ({
  handleChange,
  prevStep,
  handleRegistro,
  formValues,
  talleres,
}) => {
  const [eventosAgrupados, setEventosAgrupados] = useState([]);
  let numFechas = 0;

  useEffect(() => {
    const agrupados = talleres.reduce((acumulador, evento) => {
      const fechaInicio = evento.fecha_inicio.split(" ")[0];
      if (!acumulador[fechaInicio]) {
        acumulador[fechaInicio] = [];
        numFechas++;
      }
      acumulador[fechaInicio].push(evento);

      return acumulador;
    }, {});

    handleChange({ target: { value: numFechas } }, "num_fechas");
    setEventosAgrupados(Object.values(agrupados));
  }, []);

  return (
    <>
      <h6>
        <i className="fa-solid fa-screwdriver-wrench me-2"></i>Talleres
      </h6>
      <p className="text-muted text-xxs fw-bold">
        Si quieres inscribirte a un taller favor de seleccionar el de tu
        preferencia
      </p>
      {eventosAgrupados.length > 0 ? (
        <>
          {eventosAgrupados.map((diaEvento, index) => (
            <div className="form-group mb-2" key={index}>
              <label htmlFor="" className="mb-0">
                {format(
                  new Date(diaEvento[0].fecha_inicio.split(" ")[0]),
                  "d 'de' MMMM",
                  { locale: es }
                )}
                :
              </label>
              <select
                name={`taller_${index}`}
                className="form-control"
                onChange={(e) => handleChange(e, `taller_${index}`)}
                value={formValues[`taller_${index}`] || ""}
                key={index}
              >
                <option value="">Selecciona una opción</option>
                {diaEvento.map((tallerDia, index) => (
                  <option value={tallerDia.id} key={tallerDia.id}>
                    {tallerDia.nombre}
                  </option>
                ))}
              </select>
            </div>
          ))}
        </>
      ) : (
        <>
          <p className="text-center">
            El cupo de los talleres ya fue agotado, te invitamos a preguntar el
            día del evento directamente en el taller de tu interés.
          </p>
        </>
      )}

      <hr />

      <div className="d-flex mt-3">
        <button
          className="btn btn-secondary bg-gradient-secondary ms-0 me-auto"
          type="button"
          onClick={prevStep}
        >
          Anterior
        </button>
        <button
          type="button"
          className="btn btn-info bg-gradient-info ms-auto me-0"
          onClick={handleRegistro}
        >
          Registrarme
        </button>
      </div>
    </>
  );
};

export default Step4;
