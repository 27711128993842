import React from "react";

const Step3 = ({ handleChange, nextStep, prevStep, formValues }) => (
  <>
    <h6>
      <i className="fas fa-map-pin me-2"></i>Evento y Ubicación
    </h6>
    <div className="form-group mb-1">
      <label htmlFor="" className="mb-0">
        * Rol de participación
      </label>
      <select
        name="rol_participacion"
        className="form-control"
        onChange={(e) => handleChange(e, `rol_participacion`)}
        value={formValues.rol_participacion || ""}
      >
        <option value="" disabled>
          Selecciona una opción
        </option>
        <option value="Académico">Académico</option>
        <option value="Estudiante">Estudiante</option>
        <option value="Otro">Otro</option>
      </select>
    </div>
    <div className="form-group mb-1">
      <label htmlFor="" className="mb-0">
        * Tipo de participación
      </label>
      <select
        name="tipoParticipacion"
        className="form-control"
        onChange={(e) => handleChange(e, `tipoParticipacion`)}
        value={formValues.tipoParticipacion || ""}
      >
        <option value="" disabled>
          Seleccione una opción
        </option>
        <option value="Asistente">Asistente</option>
        <option value="Ponente">Ponente</option>
        <option value="Conferencista">Conferencista</option>
        <option value="Tallerista">Tallerista</option>
      </select>
    </div>
    <div className="form-group mb-1">
      <label htmlFor="" className="mb-0">
        * País
      </label>
      <input
        type="text"
        className="form-control"
        name="pais"
        placeholder="País"
        aria-label="País"
        onChange={(e) => handleChange(e, `pais`)}
        value={formValues.pais || ""}
      />
    </div>
    <div className="form-group mb-1">
      <label htmlFor="" className="mb-0">
        * Estado
      </label>
      <input
        type="text"
        className="form-control"
        name="estado"
        placeholder="Estado"
        aria-label="Estado"
        onChange={(e) => handleChange(e, `estado`)}
        value={formValues.estado || ""}
      />
    </div>
    <div className="form-group mb-1">
      <label htmlFor="" className="mb-0">
        * Ciudad
      </label>
      <input
        type="text"
        className="form-control"
        name="ciudad"
        placeholder="Ciudad"
        aria-label="Ciudad"
        onChange={(e) => handleChange(e, `ciudad`)}
        value={formValues.ciudad || ""}
      />
    </div>

    <div className="form-group mb-1">
      <label htmlFor="" className="mb-0">
        * ¿Cómo se enteró del evento?
      </label>
      <select
        name="comoSeEntero"
        className="form-control"
        onChange={(e) => handleChange(e, `comoSeEntero`)}
        value={formValues.comoSeEntero || ""}
      >
        <option value="" disabled>
          Seleccione una opción
        </option>
        <option value="Redes_sociales">Redes Sociales</option>
        <option value="Email">Email</option>
        <option value="Pagina_web">Página del evento</option>
        <option value="Universidad_institucion">
          Universidad o Institución
        </option>
      </select>
    </div>

    <hr />

    <div className="d-flex mt-3">
      <button
        className="btn btn-secondary bg-gradient-secondary ms-0 me-auto"
        type="button"
        onClick={prevStep}
      >
        Anterior
      </button>
      <button
        className="btn btn-secondary bg-gradient-secondary  ms-auto me-0"
        type="button"
        onClick={nextStep}
      >
        Siguiente
      </button>
    </div>
  </>
);

export default Step3;
