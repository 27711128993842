import React, { useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import WelcomeHeader from "../../../components/components-common/WelcomeHeader";

const md5 = require("blueimp-md5");

const ResetPassword = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(token);
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}resetPassword`, {
        token: token,
        newPassword: md5(newPassword, process.env.REACT_APP_PASSWORD_KEY),
      })
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "¡Se ha cambiado tu contraseña correctamente!",
          text: "Ingresa tu correo electrónico y tu nueva contraseña para iniciar sesión.",
          showConfirmButton: false,
          timer: 8000,
        }).then((result) => {
          navigate("/login");
        });
      })
      .catch((error) => alert(error.response.data.message));
  };

  return (
    <main className="main-content mt-0 ps body-fade">
      <WelcomeHeader
        title="¡Bienvenidos Innova Forum 2024!"
        subtitle="Estamos encantados de que nos acompañes a este gran evento."
      />
      <div className="container mt-2 mb-5">
        <div className="row mt-lg-n11 mt-md-n11 mt-n11 justify-content-center">
          <div className="col-xl-4 col-lg-5 col-md-7 mx-auto">
            <div className="card z-index-0">
              <div className="card-header text-center pt-4 pb-0">
                <h5>Nueva contraseña</h5>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <input
                      type="password"
                      value={newPassword}
                      className="form-control"
                      onChange={(e) => setNewPassword(e.target.value)}
                      placeholder="Escribe una nueva contraseña"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="password"
                      value={repeatNewPassword}
                      className="form-control"
                      onChange={(e) => setRepeatNewPassword(e.target.value)}
                      placeholder="Escribe nuevamente la contraseña"
                      required
                    />
                  </div>
                  <div className="text-center mt-2">
                    <button
                      type="submit"
                      className="btn btn-primary bg-gradient-primary w-100 my-2 mb-2"
                    >
                      Actualizar contraseña
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ResetPassword;
