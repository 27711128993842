import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Image from "react-bootstrap/Image";
import imageEvento from "../../assets/images/innovaImage.png";
import { useEffect, useState } from "react";
import Axios from "axios";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import "moment/locale/es";

const Evento = () => {
  const [evento, setEvento] = useState({});
  const { id } = useParams();
  moment.locale();

  Axios.default.withCredentials = true;
  useEffect(() => {
    const fetchEvent = async () => {
      try {
        await Axios.get(
          `${process.env.REACT_APP_API_BASE_URL}api/admin/eventos/${id}`
        )
          .then((response) => {
            setEvento(response.data);
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (error) {
        console.error(error);
      }
    };
    fetchEvent();
  }, []);
  return (
    <div style={{ position: "relative", zIndex: "1" }}>
      <h4 className="text-white ms-4">Evento</h4>
      <div className="container-fluid py-4">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col col-sm-2 col-12">
                <Image
                  src={imageEvento}
                  fluid
                  alt="Encuentro Cultural y Deportivo"
                  style={{ borderRadius: "8px", width: "300px" }}
                />
              </div>
              <div className="col col-sm-10 col-12">
                <h3 className="mt-2">{evento.titulo}</h3>

                <p className="text-muted lh-1">
                  <small>
                    <i class="fa-solid fa-calendar-day me-2"></i>{" "}
                    {moment(evento.fecha_inicio).format(
                      "DD MMMM YYYY h:mm:ss a"
                    )}
                  </small>
                </p>
                <p className="text-muted lh-1">
                  <small>
                    <i class="fa-solid fa-location-dot me-2"></i> {evento.lugar}
                  </small>
                </p>

                <hr />
              </div>
              <hr />
              <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="mb-3 w-100"
              >
                <Tab
                  eventKey="home"
                  title="Detalles del Evento"
                  className="w-100"
                >
                  <label htmlFor="">Descripción</label>
                  <p>{evento.descripcion}</p>

                  <p>
                    <i class="fas fa-ticket-alt me-2"></i>
                    {evento.tipo_evento_nombre}
                  </p>
                  <p>
                    <i class="fa-solid fa-users me-2"></i>
                    {evento.capacidad
                      ? evento.capacidad
                      : "Capacidad no establecida"}
                  </p>

                  <p>
                    <i class="fas fa-calendar-alt me-2"></i>
                    Inicio de registro:
                    {"  "}
                    {moment(evento.fecha_inicio_registro).format(
                      "DD MMMM YYYY h:mm:ss a"
                    )}
                    {"  "}
                    {evento.fecha_fin_registro
                      ? moment(evento.fecha_fin_registro).format(
                          "DD MMMM YYYY h:mm:ss a"
                        )
                      : ""}
                  </p>
                  <p>
                    <i class="fas fa-star me-2"></i>
                    {evento.invitados}
                  </p>
                  <p>
                    <i class="fas fa-link me-2"></i>
                    <Link
                      to={evento.url_evento}
                      className="text-primary"
                      target="_blank"
                    >
                      {evento.url_evento}
                    </Link>
                  </p>
                </Tab>
                <Tab eventKey="contact" title="Comentarios y notificaciones">
                  Aquí estaría cualquier información extra que se necesitara
                  precisar
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Evento;
