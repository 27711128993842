import QRCode from "react-qr-code";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Axios from "axios";
import md5 from "blueimp-md5";
import imageLayout from "../../assets/images/profile-layout-header.webp";

import Countdown from "../../components/components-users/Countdown";
import TallerComponent from "../../components/components-users/TallerComponent";

const Ticket = () => {
  const [auth, setAuth] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [nombre, setNombre] = useState("");
  const [correo_electronico, setCorreo_electronico] = useState("");
  const [id, setId] = useState("");
  const [talleres, setTalleres] = useState([]);
  const navigate = useNavigate();

  Axios.defaults.withCredentials = true;
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API_BASE_URL}`)
      .then((res) => {
        if (res.data.valid) {
          setAuth(true);
          setId(res.data.id_user);
        } else {
          navigate("/login");
          setAuth(false);
        }
      })
      .catch((err) => {
        navigate("/login");
        console.error(err);
      });
  }, [navigate]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        if (id) {
          // Verificar si id tiene un valor válido
          await Axios.get(
            `${process.env.REACT_APP_API_BASE_URL}usuario/${id}`
          ).then((response) => {
            setNombre(response.data.user.nombre_completo);
            setCorreo_electronico(response.data.user.correo_electronico);
            if (response.data.talleres) {
              setTalleres(response.data.talleres);
            }
          });
        }
      } catch (error) {
        navigate("/login");
        console.error(error);
      }
    };
    fetchUser();
  }, [id, navigate]);

  const handleCloseGuardar = () => {
    setShow(false);
    Axios.patch(`${process.env.REACT_APP_API_BASE_URL}usuario/` + id, {
      nombre: nombre,
    })
      .then(() => {
        alert("Fue modificado con éxito");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLogout = () => {
    Axios.get(`${process.env.REACT_APP_API_BASE_URL}auth/logout`)
      .then((res) => {
        if (res.data.Status === "Success") {
          window.location.reload();
        } else {
          alert("Hubo un problema al cerrar la sesión.");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      {auth ? (
        <div className="g-sidenav-show bg-gray-100 body-fade">
          <div
            className="position-absolute w-100 min-height-300 top-0"
            style={{
              backgroundImage: `url(${imageLayout})`,
              backgroundPositionY: "50%",
            }}
          >
            <span className="mask bg-dark opacity-6"></span>
          </div>

          <div className="main-content position-relative max-height-vh-100 h-100 ">
            <div className="card shadow-lg mx-4 mt-5">
              <div className="card-body p-3">
                <div className="row gx-4">
                  <div className="col-auto"></div>
                  <div className="col-auto my-auto">
                    <div className="h-100">
                      <h5 className="mb-1">{nombre}</h5>
                      <p className="mb-0 font-weight-bold text-sm">
                        {correo_electronico}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
                    <div className="nav-wrapper position-relative end-0">
                      <ul
                        className="nav nav-pills nav-fill p-1 me-4"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <Link
                            className="nav-link mb-0 px-0 py-1 d-flex align-items-center justify-content-center"
                            data-bs-toggle="tab"
                            to="#"
                            role="tab"
                            aria-selected="false"
                            tabIndex="-1"
                            onClick={handleLogout}
                          >
                            <i className="fa-solid fa-arrow-right-from-bracket"></i>
                            <span className="ms-2">Salir</span>
                          </Link>
                        </li>

                        <div
                          className="moving-tab position-absolute nav-link"
                          style={{
                            padding: "0px",
                            transition: "all 0.5s ease 0s",
                            transform: "translate3d(0px, 0px, 0px)",
                            width: "100%",
                          }}
                        >
                          <Link
                            className="nav-link mb-0 px-0 py-1 d-flex align-items-center justify-content-center active"
                            data-bs-toggle="tab"
                            to="#"
                            role="tab"
                            aria-selected="true"
                          >
                            -
                          </Link>
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid py-6">
              <div className="row">
                <div className="col">
                  <div className="card card-profile">
                    <div className="row justify-content-center">
                      <div className="text-center mt-4">
                        <h5>Boleto</h5>
                        <span className="font-weight-light mx-3">
                          Presenta este QR al ingreso de Innova Forum 2024
                        </span>
                      </div>
                      <div className="col">
                        <div className="mt-n1 mt-lg-n1 mb-3 mb-lg-0 text-center">
                          <Link to="#">
                            <QRCode
                              value={
                                `${process.env.REACT_APP_API_BASE_URL}admin/checkInvitation/` +
                                md5(correo_electronico)
                              }
                              className="mt-3"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="card-body pt-0">
                      <div className="row">
                        <div className="col">
                          <h5 className="text-lg font-weight-bolder text-center mx-auto d-grid text-center mt-3">
                            Faltan
                          </h5>

                          <div className="d-flex justify-content-center">
                            <Countdown targetDate="2024-09-03T12:00:00" />
                          </div>
                        </div>
                      </div>
                      <div className="text-center mt-3">
                        <div className="mt-3">
                          <i className="ni business_briefcase-24 mr-2"></i>
                          Conjunto Santander de Artes Escénicas
                        </div>
                        <div>
                          <i className="ni education_hat mr-2"></i>Del 3 al 5 de
                          septiembre de 2024
                        </div>
                        <div>
                          <a
                            href="https://innovaforum.udg.mx/"
                            className="my-0"
                          >
                            https://innovaforum.udg.mx/
                          </a>
                        </div>
                        <div>
                          <i className="ni education_hat mr-2"></i>33 3268 8888
                          Opción 1
                        </div>
                      </div>

                      <div className="mt-3">
                        <h3>Talleres registrados</h3>
                        {talleres && talleres.length > 0 ? (
                          talleres.map((taller) => (
                            <TallerComponent
                              key={taller.id_taller}
                              taller={taller}
                            />
                          ))
                        ) : (
                          <p>No hay talleres registrados</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Mis datos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <label htmlFor="">Nombre</label>
              <input
                type="text"
                className="form-control"
                value={nombre}
                onChange={(event) => {
                  setNombre(event.target.value);
                }}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cerrar
              </Button>
              <Button variant="primary" onClick={handleCloseGuardar}>
                Guardar cambios
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ) : (
        <div className="vh-100 d-flex justify-content-center align-items-center">
          <h1 className="text-center">Cargando...</h1>
        </div>
      )}
    </>
  );
};

export default Ticket;
