import logo from "../../assets/images/logoUDGPLUS.png";
import { Link } from "react-router-dom";

const adminSideBar = ({ showSideBar, handleShowSideBar, selectedPath }) => {
  const navigationItems = [
    {
      type: "item",
      href: "/admin/dashboard",
      path: "dashboard",
      isActive: true,
      icon: <i className="fa-solid fa-computer text-primary"></i>,
      text: "Dashboard",
    },

    {
      type: "item",
      href: "/admin/participantes",
      path: "participantes",
      icon: <i className="fa-solid fa-users text-success"></i>,
      text: "Participantes",
    },
    {
      type: "item",
      href: "/admin/talleres",
      path: "talleres",
      isActive: true,
      icon: <i className="fa-solid fa-pen-ruler text-warning"></i>,
      text: "Talleres",
    },
    {
      type: "item",
      href: "/admin/eventos",
      path: "eventos",
      icon: <i class="fas fa-list-alt text-info"></i>,
      text: "Eventos",
    },
    {
      type: "item",
      href: "/admin/reportes",
      path: "reportes",
      icon: <i className="fa-solid fa-chart-simple text-danger"></i>,
      text: "Reportes y estadísticas",
      style: {
        display: "none",
      },
    },
    {
      type: "separator",
      heading: "Cuenta",
    },
    {
      type: "item",
      href: "/admin/perfil",
      path: "perfil",
      icon: <i className="fa-solid fa-user text-dark"></i>,
      text: "Perfil",
      style: {
        display: "none",
      },
    },
  ];

  const NavigationItem = ({ item, active }) => (
    <li className={`nav-item ${active}`} style={item.style}>
      <Link to={item.href}>
        <p className="nav-link">
          <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center ">
            {item.icon}
          </div>
          <span className="nav-link-text ms-1">{item.text}</span>
        </p>
      </Link>
    </li>
  );

  const NavigationSeparator = ({ heading }) => (
    <>
      {heading && (
        <li className="nav-item mt-3" style={{ display: "none" }}>
          <h6 className="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6">
            {heading}
          </h6>
        </li>
      )}
    </>
  );

  return (
    <div show={showSideBar} onHide={handleShowSideBar} responsive="md">
      <div
        className={`sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-4 ps bg-white ${
          showSideBar ? "d-block" : "d-none"
        }`}
        id="sidenav-main"
      >
        <div className="sidenav-header">
          <i
            className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-xl-none d-block"
            aria-hidden="true"
            id="iconSidenav"
            onClick={handleShowSideBar}
          ></i>
          <a className="navbar-brand text-center" href="./">
            <img
              src={logo}
              className="navbar-brand-img h-100"
              alt="main_logo"
            />
          </a>
        </div>
        <hr className="horizontal dark mt-0" />
        <div
          className="collapse navbar-collapse w-auto ps"
          id="sidenav-collapse-main"
        >
          <ul className="navbar-nav">
            {navigationItems.map(function (data) {
              return (
                <div key={data.type + data.text}>
                  {data.type === "item" && (
                    <NavigationItem
                      item={data}
                      active={selectedPath == data.path ? "active" : ""}
                    />
                  )}
                  {data.type === "separator" && (
                    <NavigationSeparator heading={data.heading} />
                  )}
                </div>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default adminSideBar;
