import { Link } from "react-router-dom";
import Axios from "axios";
import { useEffect, useState } from "react";
import Step1 from "../../../components/components-users/login/FormStep1";
import Step2 from "../../../components/components-users/login/FormStep2";
import Step3 from "../../../components/components-users/login/FormStep3";
import Step4 from "../../../components/components-users/login/FormStep4";
import ProgressBar from "../../../components/components-users/login/ProgressBar";
import WelcomeHeader from "../../../components/components-common/WelcomeHeader";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const md5 = require("blueimp-md5");

const Form = () => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState([]);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    nombre: "",
    correoElectronico: "",
    password: "",
    repassword: "",
    sexo: "",
    institucion: "",
    centro_universitario: "",
    gradoEstudios: "",
    rol_participacion: "",
    tipoParticipacion: "",
    ciudad: "",
    estado: "",
    pais: "",
    comoSeEntero: "",
  });
  const [talleres, setTalleres] = useState([]);
  const totalSteps = 4;

  // FUNCTIONS
  // Función para manejar cambios en el formulario
  const handleChange = (event, name) => {
    const { value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // Funcionesn para avanzar o retroceder al siguiente paso
  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  // Función para enviar los datos a la API
  const add = (data) => {
    console.log(data);
    Axios.post(`${process.env.REACT_APP_API_BASE_URL}usuario/create`, data)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title:
            "¡Tu cuenta ha sido agregada exitosamente para participar en Innova Forum 2024!",
          text: "Obtén tu código QR del evento iniciando sesión en este sistema",
          showConfirmButton: false,
          timer: 8000,
        }).then((result) => {
          navigate("/login");
        });
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.errors) {
          setErrors(err.response.data.errors);
        } else {
          console.log(err);
        }
      });
  };

  // Función para manejar el registro del usuario
  const handleRegistro = async () => {
    const newErrors = [];

    // Validación de los campos obligatorios
    if (
      !formData.nombre ||
      !formData.correoElectronico ||
      !formData.password ||
      !formData.repassword ||
      !formData.sexo ||
      !formData.institucion ||
      !formData.gradoEstudios ||
      !formData.rol_participacion ||
      !formData.tipoParticipacion ||
      !formData.pais ||
      !formData.estado ||
      !formData.ciudad ||
      !formData.comoSeEntero
    ) {
      newErrors.push({ msg: "Todos los campos son obligatorios" });
    }

    // Validación del formato del correo electrónico
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.correoElectronico)) {
      newErrors.push({ msg: "El correo electrónico no es válido" });
    }

    // Validación de la coincidencia de las contraseñas
    if (formData.password !== formData.repassword) {
      newErrors.push({ msg: "Tus contraseñas no coinciden." });
    }

    // Si hay errores, actualiza el estado y no continúa
    if (newErrors.length > 0) {
      setErrors(newErrors);
      return;
    }

    // Encriptación de la contraseña
    const encryptedPassword = md5(
      formData.password,
      process.env.REACT_APP_PASSWORD_KEY
    );

    try {
      // Verificación de si el usuario ya existe
      const usuarioExistente = await Axios.get(
        `${process.env.REACT_APP_API_BASE_URL}usuario/email/` +
          formData.correoElectronico
      );
      if (usuarioExistente.data) {
        setErrors([{ msg: "El correo electrónico ya está registrado" }]);
        return;
      } else {
        // Si no existe, añade el usuario
        const updatedFormData = {
          ...formData,
          password: encryptedPassword,
        };
        add(updatedFormData);
      }
    } catch (error) {
      console.error("Hubo un problema: " + error);
      alert(
        "Hubo un problema durante el registro, favor de verificar más tarde."
      );
    }
  };

  // Hook para obtener los talleres al montar el componente
  Axios.defaults.withCredentials = true;
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API_BASE_URL}api/get/talleres/1`)
      .then((res) => {
        if (res.data) {
          setTalleres(res.data);
        } else {
          console.log("No se ha encontrado ningún taller");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <main className="main-content mt-0 ps body-fade">
      <WelcomeHeader
        title="¡Bienvenidos Innova Forum 2024!"
        subtitle="Favor de registrarse para poder obtener el boleto de ingreso al evento."
      />
      <div className="container">
        <div className="row mt-lg-n11 mt-md-n11 mt-n11 justify-content-center">
          <div className="col-xl-4 col-lg-5 col-md-7 mx-auto">
            <div className="card z-index-0 mb-5">
              <div className="card-header text-center pt-4 pb-0">
                <h5>Registrarse </h5>
              </div>
              <div className="card-body pt-1">
                <form>
                  <ProgressBar step={step} totalSteps={totalSteps} />
                  {step === 1 && (
                    <Step1
                      handleChange={handleChange}
                      nextStep={nextStep}
                      formValues={formData}
                    />
                  )}
                  {step === 2 && (
                    <Step2
                      handleChange={handleChange}
                      nextStep={nextStep}
                      prevStep={prevStep}
                      formValues={formData}
                    />
                  )}
                  {step === 3 && (
                    <Step3
                      handleChange={handleChange}
                      nextStep={nextStep}
                      prevStep={prevStep}
                      formValues={formData}
                    />
                  )}
                  {step === 4 && (
                    <Step4
                      handleChange={handleChange}
                      prevStep={prevStep}
                      handleRegistro={handleRegistro}
                      formValues={formData}
                      talleres={talleres}
                    />
                  )}

                  {errors.length > 0 && (
                    <div className="text-danger fw-bold">
                      {errors.map((error, index) => (
                        <div key={index}>{error.msg}</div>
                      ))}
                    </div>
                  )}

                  <p className="text-sm mt-3 mb-0">
                    ¿Ya habías sido registrado?{" "}
                    <Link
                      to="../Login"
                      className="text-dark font-weight-bolder"
                    >
                      Inicia sesión
                    </Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Form;
