import Axios from "axios";
import { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/es";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import * as XLSX from "xlsx";
import { Link, useOutletContext } from "react-router-dom";

import Modal from "react-bootstrap/Modal";

const Participantes = () => {
  const userData = useOutletContext();
  const [user, setUser] = useState(userData);

  const [participantes, setParticipantes] = useState([]);
  const [participante, setParticipante] = useState({});
  const [registroIngresos, setRegistroIngresos] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [show, setShow] = useState(false);
  const [showIngresos, setShowIngresos] = useState(false);
  const handleClose = () => {
    setShow(false);
    setShowIngresos(false);
  };

  const itemsPerPage = 10;
  const filteredParticipantes = participantes.filter(
    (participante) =>
      participante.nombre_completo
        .toLowerCase()
        .includes(search.toLowerCase()) ||
      participante.correo_electronico
        .toLowerCase()
        .includes(search.toLowerCase())
  );

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentPageItems = filteredParticipantes.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  moment.locale();

  Axios.defaults.withCredentials = true;
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        await Axios.get(
          `${process.env.REACT_APP_API_BASE_URL}admin/participantes/`
        ).then((response) => {
          setParticipantes(response.data);
        });
      } catch (error) {
        console.error(error);
      }
    };
    fetchUsers();
  }, []);

  Axios.defaults.withCredentials = true;
  const handleDelete = (id_participante) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Si el participante es eliminado, ya no podrás revertirlo.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f5365c",
      cancelButtonColor: "##67748e",
      confirmButtonText: "Si, eliminarlo",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          Axios.delete(
            `${process.env.REACT_APP_API_BASE_URL}admin/participantes/` +
              id_participante
          ).then((result) => {
            setParticipantes((prevParticipantes) =>
              prevParticipantes.filter(
                (participante) => participante.id !== id_participante
              )
            );
            Swal.fire({
              title: "¡Eliminado!",
              text: "El participante ha sido eliminado correctamente.",
              icon: "success",
            });
          });
        } catch (err) {
          console.error(err);
        }
      }
    });
  };

  const handleExport = () => {
    const ws = XLSX.utils.json_to_sheet(participantes);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Participantes");
    XLSX.writeFile(wb, "Participantes.xlsx");
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    setCurrentPage(0); // Reinicia la página a la primera al buscar
  };

  const handleShow = async (id_participante) => {
    setParticipante(
      participantes.find((participante) => participante.id == id_participante)
    );
    setShow(true);
  };

  const handleShowIngresos = async (id_participante) => {
    setParticipante(
      participantes.find((participante) => participante.id == id_participante)
    );

    try {
      await Axios.get(
        `${process.env.REACT_APP_API_BASE_URL}api/admin/participantes/${id_participante}/registro-ingresos`
      ).then((response) => {
        setRegistroIngresos(response.data);
      });
    } catch (error) {
      console.error(error);
    }
    setShowIngresos(true);
  };

  return (
    <div style={{ position: "relative", zIndex: "1" }}>
      <h4 className="text-white ms-4">Participantes</h4>
      <div className="container-fluid py-4">
        <div className="card">
          <div className="card-body">
            <div className="d-flex">
              <input
                type="text"
                className="form-control me-2 h-100 ms-auto"
                style={{ width: "150px" }}
                placeholder="Buscar..."
                value={search}
                onChange={handleSearch}
              />
              <button
                className="btn text-sm fw-bold btn-outline-primary border-0 my-auto text-dark"
                onClick={handleExport}
              >
                <i class="fa-solid fa-file-export me-2"></i>
                Exporta Excel
              </button>
            </div>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    {/*<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      #
                    </th>*/}
                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Nombre completo
                    </th>
                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ">
                      Correo electronico
                    </th>
                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Fecha de registro
                    </th>
                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center">
                      Estado de registro
                    </th>
                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center">
                      Núm ingresos
                    </th>

                    {user.tipo_usuario === "admin" ? (
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center">
                        Acciones
                      </th>
                    ) : (
                      <></>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {currentPageItems.map((participante, index) => (
                    <tr key={index}>
                      {/*<td className="text-center">{index + 1}</td>*/}
                      <td className="align-middle">
                        <h6 className="text-sm">
                          <Link
                            to="#"
                            className="fw-bolder"
                            onClick={() => handleShow(participante.id)}
                          >
                            {participante.nombre_completo}
                          </Link>
                        </h6>
                      </td>
                      <td className="text-secondary text-xs align-middle">
                        {participante.correo_electronico}
                      </td>
                      <td className="text-secondary text-xs my-auto align-middle">
                        {moment(participante.fecha_registro).format(
                          "DD MMMM YYYY h:mm:ss a"
                        )}
                      </td>
                      <td className="align-middle text-center">
                        <p
                          className={`badge badge-sm ${
                            participante.estado_registro === "Activo"
                              ? "bg-gradient-info"
                              : "bg-gradient-secondary"
                          } text-center text-white mb-0 fw-bold`}
                        >
                          {participante.estado_registro}
                        </p>
                      </td>
                      <td className="text-center align-middle">
                        <h6>
                          {participante.num_ingresos ? (
                            <Link
                              to="#"
                              className="fw-bolder"
                              onClick={() =>
                                handleShowIngresos(participante.id)
                              }
                            >
                              {participante.num_ingresos}
                            </Link>
                          ) : (
                            <small className="text-secondary text-xs my-auto align-middle">
                              No hay ingresos
                            </small>
                          )}
                        </h6>
                      </td>
                      {user.tipo_usuario === "admin" ? (
                        <td className="text-center">
                          <Link
                            to={`./${participante.id}`}
                            className=" border-none mb-0 text-sm fw-bold me-3"
                            style={{ color: "#233dd2" }}
                          >
                            Editar
                          </Link>
                          <Link
                            to="#"
                            className="text-danger border-none mb-0 text-sm fw-bold"
                            onClick={() => handleDelete(participante.id)}
                          >
                            Eliminar
                          </Link>
                        </td>
                      ) : (
                        <></>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              pageCount={Math.ceil(filteredParticipantes.length / itemsPerPage)}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={({ selected }) => setCurrentPage(selected)}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div
            className="modal-header custon-header"
            style={{
              background:
                "linear-gradient(90deg, hsla(273, 54%, 56%, 1) 0%, hsla(247, 47%, 51%, 1) 100%)",
              height: "90px",
              position: "relative",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
              padding: 0,
            }}
          >
            <img
              src="https://static.vecteezy.com/system/resources/thumbnails/001/840/618/small/picture-profile-icon-male-icon-human-or-people-sign-and-symbol-free-vector.jpg"
              alt="User Profile"
              className="profile-img"
              style={{
                width: "90px",
                height: "90px",
                borderRadius: "50%",
                border: "3px solid white",
                position: "absolute",
                bottom: "-30px",
              }}
            />
          </div>
          <Modal.Title className="text-center mt-5">
            {participante.nombre_completo}
          </Modal.Title>
          <div className="text-center">
            <p className="mb-0">{participante.correo_electronico}</p>
            <p className="mt-0">
              {moment(participante.fecha_registro).format("LL - h:mm:ss a")}
            </p>
            <hr />
          </div>
          <div>
            <h6 className="mb-0">Personal</h6>
            <p>
              <small className="text-dark">Institución donde trabaja:</small>{" "}
              {participante.institucion_trabaja}
            </p>
            <h6 className="mb-0">Locación</h6>
            <span className="badge bg-light text-dark mx-1">
              {participante.pais}
            </span>
            <span className="badge bg-light text-dark mx-1">
              {participante.estado}
            </span>
            <span className="badge bg-light text-dark mx-1">
              {participante.ciudad}
            </span>

            <h6 className="mt-3 mb-0">Registro</h6>
            <div className="d-flex ">
              <p className="badge badge-sm bg-light text-center text-dark mb-0 me-2">
                {participante.estado_registro}
              </p>
              <p className="badge badge-sm bg-light text-center text-dark my-auto">
                <Link
                  to="#"
                  onClick={() => {
                    handleClose();
                    handleShowIngresos(participante.id);
                  }}
                >
                  {participante.num_ingresos} Número de ingresos
                </Link>
              </p>
            </div>
            <h6 className="mt-3">Talleres inscritos</h6>

            <ul>
              <li>
                <strong>03 Sept:</strong>{" "}
                {participante.taller_03_sept || "No registrado"}
              </li>
              <li>
                <strong>04 Sept:</strong>{" "}
                {participante.taller_04_sept || "No registrado"}
              </li>
              <li>
                <strong>05 Sept:</strong>{" "}
                {participante.taller_05_sept || "No registrado"}
              </li>
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Link
            to="#"
            className="text-primary fw-bolder me-2 border-0"
            onClick={handleClose}
          >
            Cerrar
          </Link>
        </Modal.Footer>
      </Modal>
      <Modal show={showIngresos} onHide={handleClose}>
        <Modal.Body>
          <div
            className="modal-header custon-header"
            style={{
              background:
                "linear-gradient(90deg, hsla(273, 54%, 56%, 1) 0%, hsla(247, 47%, 51%, 1) 100%)",
              height: "90px",
              position: "relative",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
              padding: 0,
            }}
          >
            <img
              src="https://static.vecteezy.com/system/resources/thumbnails/001/840/618/small/picture-profile-icon-male-icon-human-or-people-sign-and-symbol-free-vector.jpg"
              alt="User Profile"
              className="profile-img"
              style={{
                width: "90px",
                height: "90px",
                borderRadius: "50%",
                border: "3px solid white",
                position: "absolute",
                bottom: "-30px",
              }}
            />
          </div>
          <Modal.Title className="text-center mt-5">
            {participante.nombre_completo}
          </Modal.Title>
          <div className="text-center">
            <p className="mb-0">{participante.correo_electronico}</p>
            <p className="mt-0">
              {moment(participante.fecha_registro).format("LL - h:mm:ss a")}
            </p>
            <hr />
          </div>
          <div>
            {registroIngresos.map((registro, index) => (
              <div className="mb-3" key={registro.id}>
                <h5>Entrada {index + 1}</h5>
                <div className="ms-3">
                  <div className="d-flex">
                    <i className="fas fa-portrait my-auto me-2"></i>{" "}
                    {registro.usuario_codigo}
                  </div>
                  <div className="d-flex">
                    <i className="fas fa-calendar-day my-auto me-2"></i>{" "}
                    {moment(registro.fecha_ingreso).format("LL")}
                  </div>
                  <div className="d-flex">
                    <i className="far fa-clock my-auto me-2"></i>{" "}
                    {moment(registro.fecha_ingreso).format("h:mm:ss A")}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Link
            to="#"
            className="text-primary fw-bolder me-2 border-0"
            onClick={handleClose}
          >
            Cerrar
          </Link>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Participantes;
