import React from "react";

const ProgressBar = ({ step, totalSteps }) => (
  <div className="progress-bar">
    <div
      className="progress-bar-filled"
      style={{ width: `${(step / totalSteps) * 100}%` }}
    ></div>
  </div>
);

export default ProgressBar;
