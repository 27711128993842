import { Outlet, useNavigate } from "react-router-dom";
import AdminSideBar from "../../components/components-admin/AdminSideBar";
import AdminTopBar from "../../components/components-admin/AdminTopBar";
import AdminBackgroundColor from "../../components/components-admin/AdminBackgroundColor";
import { useEffect, useState } from "react";
import Axios from "axios";

const LayoutAdmin = ({ selectedPath }) => {
  const [auth, setAuth] = useState(false);
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 992);
  const [showSideBar, setShowSideBar] = useState(!isSmallScreen);

  Axios.defaults.withCredentials = true;
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API_BASE_URL}admin/`)
      .then((res) => {
        if (res.data.valid) {
          setAuth(true);
          setUserData(res.data.result);
        } else {
          navigate("/admin/login");
          setAuth(false);
        }
      })
      .catch((err) => {
        navigate("/admin/login");
        console.error(err);
      });

    handleResize();
  }, [navigate]);

  const handleShowSideBar = () => {
    showSideBar ? setShowSideBar(false) : setShowSideBar(true);
  };

  const handleResize = () => {
    const isSmall = window.innerWidth < 992;
    setIsSmallScreen(isSmall);
    setShowSideBar(!isSmall); // Mostrar u ocultar la barra lateral dependiendo del tamañoue);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  return (
    <div className="body-fade">
      {auth ? (
        <>
          <AdminBackgroundColor />
          <AdminTopBar
            isSmallScreen={isSmallScreen}
            showSideBar={showSideBar}
            handleShowSideBar={handleShowSideBar}
            context={userData}
          />
          <div className="row pe-0 me-0">
            <div className="col col-lg-2 col-md-4 col-sm-6 col-xl-2">
              <AdminSideBar
                showSideBar={showSideBar}
                handleShowSideBar={handleShowSideBar}
                selectedPath={selectedPath}
              />
            </div>
            <div className="col pe-0 col-12 col-lg-10 col-md-12 col-sm-12 col-xl-10">
              <div className={{ Zindex: 1 }}>
                <Outlet context={userData} />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="vh-100 d-flex justify-content-center align-items-center">
          <h1 className="text-center">Cargando...</h1>
        </div>
      )}
    </div>
  );
};

export default LayoutAdmin;
