import Axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import "moment/locale/es";

const InvitacionExito = () => {
  const { code } = useParams();
  const [user, setUser] = useState([]);

  Axios.defaults.withCredentials = true;
  useEffect(() => {
    const getDataParticipante = async () => {
      try {
        await Axios.get(
          `${process.env.REACT_APP_API_BASE_URL}admin/participanteByCode/` +
            code
        ).then((result) => {
          setUser(result.data);
        });
      } catch (error) {
        console.error(error);
      }
    };
    getDataParticipante();
  }, [code]);

  return (
    <div className="container mt-5">
      <div className="card p-3">
        <div className="text-center">
          <i
            className="fa-solid fa-check"
            style={{ fontSize: "120px", color: "darkgreen" }}
          ></i>
        </div>
        <h2 className="text-center">Invitación confirmada</h2>
        <p className="text-center text-muted fw-light fs-6">
          La invitación fue validada con éxito, gracias por venir.
        </p>

        <div className="mt-2 text-center">
          <h5 className="mb-0">{user.nombre_completo}</h5>
          <p className="mt-0">{user.correo_electronico}</p>
          {moment(user.fecha_registro).format("DD MMMM YYYY")}
          <br />
          <p>{user.genero}</p>
        </div>
      </div>
    </div>
  );
};

export default InvitacionExito;
