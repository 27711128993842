import React, { useState, useEffect } from "react";

const Countdown = ({ targetDate }) => {
  const timerComponents = [];

  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        dias: Math.floor(difference / (1000 * 60 * 60 * 24)),
        horas: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutos: Math.floor((difference / 1000 / 60) % 60),
        segundos: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = {
        dias: 0,
        horas: 0,
        minutos: 0,
        segundos: 0,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <div key={interval} className="me-2">
        <span className="text-lg font-weight-bolder me-1">
          {timeLeft[interval]}
        </span>
        <span className="text-sm opacity-8">{interval} </span>
      </div>
    );
  });

  return (
    <div className="">
      <div className="d-grid text-center d-flex">
        {timerComponents.length ? (
          timerComponents
        ) : (
          <span>¡Contador terminado!</span>
        )}
      </div>
    </div>
  );
};

export default Countdown;
