import React from "react";

const Step2 = ({ handleChange, nextStep, prevStep, formValues }) => (
  <>
    <h6>
      <i className="fas fa-briefcase me-2"></i>Profesional y Académica
    </h6>
    <div className="form-group mb-1">
      <label htmlFor="" className="mb-0">
        * Institución donde se desempeña
      </label>
      <input
        type="text"
        className="form-control"
        name="institucion"
        placeholder="Institución donde trabaja"
        aria-label="Institución donde trabaja"
        onChange={(e) => handleChange(e, `institucion`)}
        value={formValues.institucion || ""}
      />
    </div>
    <div className="form-group mb-1">
      <label htmlFor="" className="mb-0">
        Centro Universitario
      </label>
      <input
        type="text"
        className="form-control"
        name="centro_universitario"
        placeholder="(En caso de ser parte de la UdeG)"
        aria-label="(En caso de ser parte de la UdeG)"
        onChange={(e) => handleChange(e, `centro_universitario`)}
        value={formValues.centro_universitario || ""}
      />
    </div>
    <div className="form-group mb-1">
      <label htmlFor="" className="mb-0">
        * Último grado de estudios
      </label>
      <input
        type="text"
        className="form-control"
        name="gradoEstudios"
        placeholder="Último grado de estudios"
        aria-label="Último grado de estudios"
        onChange={(e) => handleChange(e, `gradoEstudios`)}
        value={formValues.gradoEstudios || ""}
      />
    </div>

    <div className="d-flex mt-3">
      <button
        className="btn btn-secondary bg-gradient-secondary ms-0 me-auto"
        type="button"
        onClick={prevStep}
      >
        Anterior
      </button>
      <button
        className="btn btn-secondary bg-gradient-secondary  ms-auto me-0"
        type="button"
        onClick={nextStep}
      >
        Siguiente
      </button>
    </div>
  </>
);

export default Step2;
