import { Line, Bar, Pie } from "react-chartjs-2";

const ChartDashboard = ({ type, title, height, dataset }) => {
  const optionsLine = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom", // Posición de la leyenda
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Fecha",
        },
      },
      y: {
        title: {
          display: true,
          text: "Registros Semanales",
        },
      },
    },
  };

  const optionsConocimientoEvento = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: "Registros por conocimiento evento",
      },
      legend: {
        display: false, // Para que la leyenda no se muestre, como en tu gráfico
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Género",
        },
      },
      y: {
        title: {
          display: true,
          text: "Total de registros",
        },
        min: 0, // Valor mínimo del eje Y
      },
    },
  };

  const optionsSexo = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: "Registros por Sexo",
      },
      legend: {
        display: false, // Para que la leyenda no se muestre, como en tu gráfico
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Sexo",
        },
      },
      y: {
        title: {
          display: true,
          text: "Total de registros",
        },
        min: 0, // Valor mínimo del eje Y
      },
    },
  };

  const optionsTipoRol = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom", // Coloca la leyenda en la parte inferior
      },
    },
  };

  const renderChart = () => {
    switch (type) {
      case "line": {
        const dataLine = {
          labels:
            dataset && dataset.length > 0
              ? dataset.map((item) => item.primer_dia + " - " + item.ultimo_dia)
              : [],
          datasets: [
            {
              label: "Registros Semanales",
              fill: true, // Esto permite el área sombreada bajo la línea
              backgroundColor: "rgba(116, 139, 252, 0.3)", // Color del área sombreada con transparencia
              borderColor: "#748bfc", // Color de la línea
              tension: 0.4, // Hace las líneas curveadas (efecto de suavización)
              data:
                dataset && dataset.length > 0
                  ? dataset.map((item) => item.totalRegistros)
                  : [], // Los datos reales de tu gráfico
            },
          ],
        };
        return <Line data={dataLine} options={optionsLine} height={height} />;
      }
      case "barConocimiento": {
        const dataConocimientoEvento = {
          labels:
            dataset && dataset.length > 0
              ? dataset.map((item) => item.conocimiento_evento)
              : [],
          datasets: [
            {
              label: "Registros",
              data:
                dataset && dataset.length > 0
                  ? dataset.map((item) => item.totalRegistrosConocimiento)
                  : [],
              backgroundColor: "#3b426e", // Color de las barras
            },
          ],
        };
        return (
          <Bar
            data={dataConocimientoEvento}
            options={optionsConocimientoEvento}
            height={200}
          />
        );
      }
      case "pie": {
        const dataTipoRol = {
          labels:
            dataset && dataset.length > 0
              ? dataset.map((item) => item.rol_participacion)
              : [], // Las etiquetas del gráfico
          datasets: [
            {
              label: "Registros",
              data:
                dataset && dataset.length > 0
                  ? dataset.map((item) => item.totalRegistrosParticipacion)
                  : [], // Los datos correspondientes a cada tarea
              backgroundColor: ["#17c1e8", "#5e72e4", "#3A416F", "#a8b8d8"],
            },
          ],
        };
        return <Pie data={dataTipoRol} options={optionsTipoRol} height={200} />;
      }
      case "barGenero": {
        const dataGenero = {
          labels:
            dataset && dataset.length > 0
              ? dataset.map((item) => item.sexo)
              : [],
          datasets: [
            {
              label: "Registros",
              data:
                dataset && dataset.length > 0
                  ? dataset.map((item) => item.totalRegistrosSexo)
                  : [],
              backgroundColor: "#3b426e", // Color de las barras
            },
          ],
        };
        return <Bar data={dataGenero} options={optionsSexo} height={200} />;
      }
      default:
        return null;
    }
  };

  return (
    <div className="card z-index-2 h-100">
      <div className="card-header pb-0 pt-3 bg-transparent">
        <h6 className="text-capitalize">{title}</h6>
      </div>
      <div className="card-body p-0 my-0">{renderChart()}</div>
    </div>
  );
};

export default ChartDashboard;
