import { Carousel } from "react-bootstrap";
import carouselImage1 from "../../assets/images/carousel-1.jpg";
import { useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import Axios from "axios";
import Chart from "chart.js/auto";
import ChartDashboard from "../../components/components-admin/ChartDashboard";
import { Line, Bar, Pie } from "react-chartjs-2";

const Dashboard = () => {
  const [totalRegistros, setTotalRegistros] = useState("0");
  const [totalIngresos, setTotalIngresos] = useState("0");
  const [totalTalleres, setTotalTalleres] = useState("0");
  const [registrosFechas, setRegistrosFechas] = useState({});
  const [registrosConocimiento, setRegistrosConocimiento] = useState({});
  const [registrosTipoRol, setRegistrosTipoRol] = useState({});
  const [registrosGenero, setRegistrosGenero] = useState({});

  useEffect(() => {
    const response = Axios.get(
      `${process.env.REACT_APP_API_BASE_URL}api/admin/stadistics`
    )
      .then((response) => {
        setTotalRegistros(response.data.totalRegistrados);
        setTotalIngresos(response.data.totalIngresos);
        setTotalTalleres(response.data.totalTalleres);
        setRegistrosFechas(response.data.registrosFechas);
        setRegistrosConocimiento(response.data.registrosConocimiento);
        setRegistrosTipoRol(response.data.registrosTipoRol);
        setRegistrosGenero(response.data.registrosGenero);
      })
      .catch((error) => console.error(error));
  }, []);

  const countersItems = [
    {
      title: "Total registrados",
      total: totalRegistros,
      icon: <i className="fa-solid fa-list text-lg opacity-10"></i>,
      bgIcon: "bg-gradient-info",
      subtext: false,
    },

    {
      title: "Total ingresos",
      total: totalIngresos,
      icon: <i className="fa-solid fa-list text-lg opacity-10"></i>,
      bgIcon: "bg-gradient-success",
      subtext: {
        type: "success",
        number_percent: "2",
        text: "de la semana pasada",
      },
    },
    {
      title: "Total talleres",
      total: totalTalleres,
      icon: <i className="fa-solid fa-list text-lg opacity-10"></i>,
      bgIcon: "bg-gradient-warning",
      subtext: {
        type: "success",
        number_percent: "15",
        text: "desde último mes",
      },
    },
  ];

  const CountersDashboardCard = ({ data }) => (
    <div className="col-xl-4 col-sm-4 mb-xl-0 mb-4">
      <div className="card">
        <div className="card-body p-3">
          <div className="row">
            <div className="col-8">
              <div className="numbers">
                <p className="text-sm mb-0 text-uppercase font-weight-bold">
                  {data.title}
                </p>
                <h5 className="font-weight-bolder">{data.total}</h5>
                <p className="mb-0">
                  <span className="text-success text-sm font-weight-bolder">
                    &nbsp;
                  </span>
                </p>
              </div>
            </div>
            <div className="col-4 text-end ms-auto">
              <div
                className={`icon icon-shape ${data.bgIcon} shadow-primary text-center rounded-circle ms-auto`}
              >
                {data.icon}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div style={{ position: "relative", zIndex: "1" }}>
      <h4 className="text-white ms-4">Dashboard</h4>
      <div className="container-fluid py-3">
        <div className="row pe-0 me-0">
          {countersItems.map(function (data, index) {
            return <CountersDashboardCard data={data} key={index} />;
          })}
        </div>
      </div>
      <div className="px-4">
        <div className="row my-3">
          <ChartDashboard
            type="line"
            title="Registros"
            height={400}
            dataset={registrosFechas}
          />
        </div>
        <div className="row my-3">
          <div className="col-lg-4 mb-lg-0 mb-4">
            <ChartDashboard
              type="barConocimiento"
              title="Registros por conocimiento del evento"
              dataset={registrosConocimiento}
            />
          </div>
          <div className="col-lg-4 mb-lg-0 mb-4">
            <ChartDashboard
              type="pie"
              title="Registros por tipo de rol"
              height={200}
              dataset={registrosTipoRol}
            />
          </div>
          <div className="col-lg-4 mb-lg-0 mb-4">
            <ChartDashboard
              type="barGenero"
              title="Registros por Género"
              dataset={registrosGenero}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
