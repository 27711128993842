import Axios from "axios";
import { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/es";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import { Link, useOutletContext, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Modal from "react-bootstrap/Modal";

const ParticipanteDetails = () => {
  const userData = useOutletContext();
  const navigate = useNavigate();
  const [user, setUser] = useState(userData);
  const { id_participante } = useParams();
  const [errors, setErrors] = useState([]);
  const [participante, setParticipante] = useState({});

  const handleChange = (event, name) => {
    const { value } = event.target;
    setParticipante({ ...participante, [name]: value });
  };

  // Función para manejar el registro del usuario
  const handleSubmit = async () => {
    const newErrors = [];

    // Validación de los campos obligatorios
    if (
      !participante.nombre_completo ||
      !participante.sexo ||
      !participante.institucion_trabaja ||
      !participante.ultimo_estudio ||
      !participante.rol_participacion ||
      !participante.tipo_participacion ||
      !participante.pais ||
      !participante.estado ||
      !participante.ciudad ||
      !participante.conocimiento_evento
    ) {
      newErrors.push({ msg: "Todos los campos son obligatorios" });
    }

    // Si hay errores, actualiza el estado y no continúa
    if (newErrors.length > 0) {
      setErrors(newErrors);
      return;
    }

    add(participante);
  };

  const add = (data) => {
    console.log(data);
    Axios.patch(
      `${process.env.REACT_APP_API_BASE_URL}api/admin/participantes/${id_participante}`,
      data
    )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "¡El usuario ha sido modificado correctamente!",
          showConfirmButton: false,
          timer: 4000,
        }).then((result) => {
          navigate("/admin/participantes");
        });
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.errors) {
          setErrors(err.response.data.errors);
        } else {
          console.log(err);
        }
      });
  };

  Axios.defaults.withCredentials = true;
  useEffect(() => {
    const fetchUser = async () => {
      try {
        await Axios.get(
          `${process.env.REACT_APP_API_BASE_URL}api/admin/participantes/${id_participante}`
        ).then((response) => {
          const participanteData = response.data;
          setParticipante(participanteData);
        });
      } catch (error) {
        console.error(error);
      }
    };
    fetchUser();
  }, []);

  return (
    <div style={{ position: "relative", zIndex: "1" }}>
      <h4 className="text-white ms-4">Edición del participante</h4>
      <div className="container-fluid py-4">
        <div className="card">
          <div className="card-body">
            <div className="form-group">
              <h6>
                <i className="fas fa-user me-2"></i>Personal
              </h6>
              <label htmlFor="">* Nombre completo</label>
              <input
                type="text"
                required
                name="nombre_completo"
                placeholder="Nombre completo "
                aria-label="Nombre"
                className="form-control"
                value={participante.nombre_completo || ""}
                onChange={(e) => handleChange(e, `nombre_completo`)}
              />

              <label htmlFor="">* Sexo</label>
              <select
                name="sexo"
                className="form-control"
                onChange={(e) => handleChange(e, `sexo`)}
                value={participante.sexo || ""}
                required
              >
                <option value="Masculino">Masculino</option>
                <option value="Femenino">Femenino</option>
                <option value="Prefiero no especificar">
                  Prefiero no especificar
                </option>
              </select>
            </div>
            <hr />
            <div className="form-group">
              <h6>
                <i className="fas fa-briefcase me-2"></i>Profesional y académica
              </h6>
              <label htmlFor="">* Institución donde se desempeña</label>
              <input
                type="text"
                required
                name="institucion_trabaja"
                placeholder="Institución donde se desempeña"
                aria-label="institucion_trabaja"
                className="form-control"
                value={participante.institucion_trabaja || ""}
                onChange={(e) => handleChange(e, `institucion_trabaja`)}
              />
              <label htmlFor="">Centro universitario</label>
              <input
                type="text"
                name="centro_universitario_institucion"
                placeholder="Centro universitario "
                aria-label="centro_universitario_institucion"
                className="form-control"
                value={participante.centro_universitario_institucion || ""}
                onChange={(e) =>
                  handleChange(e, `centro_universitario_institucion`)
                }
              />
              <label htmlFor="">* Último grado de estudios</label>
              <input
                type="text"
                required
                name="ultimo_estudio"
                placeholder="Último grado de estudios"
                aria-label="ultimo_estudio"
                className="form-control"
                value={participante.ultimo_estudio || ""}
                onChange={(e) => handleChange(e, `ultimo_estudio`)}
              />
            </div>
            <hr />
            <div className="form-group">
              <h6>
                <i className="fas fa-map-pin me-2"></i>Ubicación
              </h6>
              <label htmlFor="">* País</label>
              <input
                type="text"
                required
                name="pais"
                placeholder="País"
                aria-label="pais"
                className="form-control"
                value={participante.pais || ""}
                onChange={(e) => handleChange(e, `pais`)}
              />
              <label htmlFor="">* Estado</label>
              <input
                type="text"
                required
                name="estado"
                placeholder="Estado"
                aria-label="estado"
                className="form-control"
                value={participante.estado || ""}
                onChange={(e) => handleChange(e, `estado`)}
              />
              <label htmlFor="">* Ciudad</label>
              <input
                type="text"
                required
                name="ciudad"
                placeholder="Ciudad"
                aria-label="ciudad"
                className="form-control"
                value={participante.ciudad || ""}
                onChange={(e) => handleChange(e, `ciudad`)}
              />
            </div>
            <hr />
            <div className="form-group">
              <h6>
                <i className="far fa-calendar-check me-2"></i>Evento
              </h6>
              <label htmlFor="">* Rol de participación</label>
              <select
                name="rol_participacion"
                className="form-control"
                onChange={(e) => handleChange(e, `rol_participacion`)}
                value={participante.rol_participacion || ""}
                required
              >
                <option value="Académico">Académico</option>
                <option value="Estudiante">Estudiante</option>
                <option value="Otro">Otro</option>
              </select>
              <label htmlFor="">* Tipo de participación</label>
              <select
                name="tipo_participacion"
                className="form-control"
                onChange={(e) => handleChange(e, `tipo_participacion`)}
                value={participante.tipo_participacion || ""}
                required
              >
                <option value="Asistente">Asistente</option>
                <option value="Ponente">Ponente</option>
                <option value="Conferencista">Conferencista</option>
                <option value="Tallerista">Tallerista</option>
              </select>
              <label htmlFor="">* ¿Cómo se entero del evento?</label>
              <select
                name="conocimiento_evento"
                className="form-control"
                onChange={(e) => handleChange(e, `conocimiento_evento`)}
                value={participante.conocimiento_evento || ""}
                required
              >
                <option value="Redes_sociales">Redes sociales</option>
                <option value="Email">Email</option>
                <option value="Pagina_web">Página del evento</option>
                <option value="Universidad_institucion">
                  Universidad o Institución
                </option>
              </select>
            </div>
            <hr />
            {errors.length > 0 && (
              <div className="text-danger fw-bold">
                {errors.map((error, index) => (
                  <div key={index}>{error.msg}</div>
                ))}
              </div>
            )}
            <div className="ms-auto  text-end">
              <button
                className="btn bg-gradient-success ms-auto  text-end"
                onClick={handleSubmit}
              >
                Guardar cambios
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParticipanteDetails;
