import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Axios from "axios";
import WelcomeHeader from "../../../components/components-common/WelcomeHeader";

const md5 = require("blueimp-md5");

const Login = () => {
  const [correo_electronico, setCorreo_electronico] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  Axios.defaults.withCredentials = true;
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API_BASE_URL}`)
      .then((res) => {
        if (res.data.valid) {
          navigate("/");
        } else {
          navigate("/login");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_API_BASE_URL}auth/login`,
        {
          correo_electronico: correo_electronico,
          password: md5(password, process.env.REACT_APP_PASSWORD_KEY),
        }
      );

      if (response.data.Login) {
        navigate("/");
      } else {
        alert("No fue encontrado el usuario");
      }
    } catch (error) {
      console.error("Error during login:", error);
      alert(
        "Hubo un problema al intentar iniciar sesión. Por favor, inténtalo de nuevo más tarde."
      );
    }
  };

  return (
    <main className="main-content mt-0 ps body-fade">
      <WelcomeHeader
        title="¡Bienvenidos Innova Forum 2024!"
        subtitle="Estamos encantados de que nos acompañes a este gran evento."
      />

      <div className="container mt-2 mb-5">
        <div className="row mt-lg-n11 mt-md-n11 mt-n11 justify-content-center">
          <div className="col-xl-4 col-lg-5 col-md-7 mx-auto">
            <div className="card z-index-0">
              <div className="card-header text-center pt-4 pb-0">
                <h5>Iniciar sesión </h5>
              </div>

              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Correo electrónico"
                      aria-label="Email"
                      onChange={(event) =>
                        setCorreo_electronico(event.target.value)
                      }
                    />
                  </div>
                  <div className="">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Contraseña"
                      aria-label="Password"
                      onChange={(event) => setPassword(event.target.value)}
                    />
                  </div>
                  <div className="mt-3 mb-0">
                    <Link
                      to="../ForgotPassword"
                      className="text-dark font-weight-bolder my-5 mb-0 text-sm"
                    >
                      Olvidé mi contraseña
                    </Link>
                  </div>
                  <div className="text-center mt-2">
                    <button
                      type="submit"
                      className="btn btn-primary bg-gradient-primary w-100 my-2 mb-2"
                    >
                      Iniciar sesión
                    </button>
                  </div>
                  <p className="text-sm mt-3 mb-0">
                    ¿No tienes una cuenta?{" "}
                    <Link to="../Form" className="text-dark font-weight-bolder">
                      Registrate
                    </Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Login;
