import Axios from "axios";
import { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/es";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Link, useOutletContext } from "react-router-dom";
import TallerComponent from "../../components/components-users/TallerComponent";

const Talleres = () => {
  const userData = useOutletContext();
  const [user, setUser] = useState(userData);

  const [talleres, setTalleres] = useState([]);
  const [participantes, setParticipantes] = useState([]);
  const [taller, setTaller] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const itemsPerPage = 10;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = async (id_taller) => {
    try {
      await Axios.get(
        `${process.env.REACT_APP_API_BASE_URL}admin/talleres/${id_taller}`
      ).then((responseTaller) => {
        setTaller(responseTaller.data);
      });

      await Axios.get(
        `${process.env.REACT_APP_API_BASE_URL}admin/talleres/${id_taller}/participantes`
      ).then((responseParticipantes) => {
        setParticipantes(responseParticipantes.data);
      });
    } catch (error) {}
    setShow(true);
  };

  moment.locale();

  const filteredTalleres = talleres.filter(
    (taller) =>
      taller.nombre.toLowerCase().includes(search.toLowerCase()) ||
      taller.ubicacion.toLowerCase().includes(search.toLowerCase()) ||
      taller.fecha_inicio.toLowerCase().includes(search.toLowerCase())
  );

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentPageItems = filteredTalleres.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handleSearch = (event) => {
    setSearch(event.target.value);
    setCurrentPage(0); // Reinicia la página a la primera al buscar
  };

  const handleButton = (date) => {
    setSearch(date);
  };

  Axios.defaults.withCredentials = true;
  useEffect(() => {
    const fetchTalleres = async () => {
      try {
        await Axios.get(
          `${process.env.REACT_APP_API_BASE_URL}admin/talleres/`
        ).then((response) => {
          setTalleres(response.data);
        });
      } catch (error) {
        console.error(error);
      }
    };
    fetchTalleres();
  }, []);

  const handleDelete = (id_taller) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Si el taller es eliminado, ya no podrás revertirlo.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f5365c",
      cancelButtonColor: "##67748e",
      confirmButtonText: "Si, eliminarlo",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          Axios.delete(
            `${process.env.REACT_APP_API_BASE_URL}admin/talleres/` + id_taller
          ).then((result) => {
            setTalleres((prevTalleres) =>
              prevTalleres.filter((taller) => taller.id !== id_taller)
            );
            Swal.fire({
              title: "¡Eliminado!",
              text: "El taller ha sido eliminado correctamente.",
              icon: "success",
            });
          });
        } catch (err) {
          console.error(err);
        }
      }
    });
  };

  return (
    <div style={{ position: "relative", zIndex: "1" }}>
      <h4 className="text-white ms-4">Talleres</h4>
      <div className="container-fluid py-4">
        <div className="card">
          <div className="card-body">
            <div className="row mb-md-3">
              <div className="col-md-9">
                <div className="d-flex flex-column flex-md-row">
                  <button
                    className={`btn ${
                      search === "2024-09-03"
                        ? "bg-gradient-primary"
                        : "bg-gradient-secondary"
                    } mb-2 mb-md-0 me-md-2 btn-sm`}
                    onClick={() => handleButton("2024-09-03")}
                    value="2024-09-03"
                  >
                    3 de septiembre
                  </button>
                  <button
                    className={`btn ${
                      search === "2024-09-04"
                        ? "bg-gradient-primary"
                        : "bg-gradient-secondary"
                    } mb-2 mb-md-0 me-md-2 btn-sm`}
                    onClick={() => handleButton("2024-09-04")}
                    value="2024-09-04"
                  >
                    4 de septiembre
                  </button>
                  <button
                    className={`btn ${
                      search === "2024-09-05"
                        ? "bg-gradient-primary"
                        : "bg-gradient-secondary"
                    } mb-md-0 btn-sm `}
                    onClick={() => handleButton("2024-09-05")}
                    value="2024-09-05"
                  >
                    5 de septiembre
                  </button>
                </div>
              </div>
              <div className="col-md-3 mt-3 mt-md-0">
                <div className="d-flex">
                  <input
                    type="text"
                    className="form-control me-2 form-control-sm "
                    placeholder="Buscar..."
                    value={search}
                    onChange={handleSearch}
                  />
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    {/*<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      #
                    </th>*/}
                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Nombre
                    </th>
                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center">
                      Horarios
                    </th>
                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7  text-center">
                      Ubicacion
                    </th>
                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center">
                      Núm. de registros
                    </th>
                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center">
                      Estado de registros
                    </th>
                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center">
                      Cupo
                    </th>
                    {user.tipo_usuario == "admin" ? (
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Acciones
                      </th>
                    ) : (
                      <></>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {currentPageItems.map((taller, index) => (
                    <tr key={index}>
                      <td className="align-middle">
                        <h6 className="text-sm text-wrap lh-sm my-auto fw-bolder">
                          <Link href="#" onClick={() => handleShow(taller.id)}>
                            {taller.nombre}
                          </Link>
                        </h6>
                      </td>
                      <td className="text-secondary text-xs align-middle text-center">
                        {taller.hora_inicio}
                        <br />
                        {taller.hora_fin}
                      </td>
                      <td className="text-secondary text-xs my-auto align-middle ">
                        <p className="text-xs text-center mb-0 text-wrap ">
                          {taller.ubicacion}
                        </p>
                      </td>
                      <td className="text-secondary text-xs my-auto align-middle text-center">
                        {taller.registrados}
                      </td>
                      <td className="align-middle text-center">
                        <p
                          className={`badge badge-sm ${
                            taller.disponible === "1"
                              ? "bg-gradient-info"
                              : "bg-gradient-success"
                          } text-center text-white mb-0 fw-bold`}
                        >
                          {taller.disponible === "1" ? (
                            <>Activo</>
                          ) : (
                            <>Finalizado</>
                          )}
                        </p>
                      </td>
                      <td className="align-middle text-center ">
                        <div className="d-flex align-items-center justify-content-center ">
                          <span className="me-2 text-xs font-weight-bold ">
                            {taller.disponible === "1" ? (
                              <>
                                {(
                                  (taller.registrados * 100) /
                                  taller.cupo
                                ).toFixed(0)}
                                %
                              </>
                            ) : (
                              <>100%</>
                            )}
                          </span>
                          <div>
                            <div className="progress">
                              <div
                                className={`progress-bar ${
                                  taller.disponible === "1"
                                    ? "bg-gradient-info"
                                    : "bg-gradient-success"
                                } mb-0`}
                                role="progressbar"
                                aria-valuenow={
                                  taller.disponible === "1"
                                    ? (
                                        (taller.registrados * 100) /
                                        taller.cupo
                                      ).toFixed(0)
                                    : 100
                                }
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{
                                  width: `${
                                    taller.disponible === "1"
                                      ? (
                                          (taller.registrados * 100) /
                                          taller.cupo
                                        ).toFixed(0)
                                      : 100
                                  }%`,
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </td>
                      {user.tipo_usuario === "admin" ? (
                        <td className="text-center">
                          <Link
                            to="#"
                            className="text-danger border-none mb-0 text-sm fw-bold"
                            onClick={() => handleDelete()}
                          >
                            Eliminar
                          </Link>
                        </td>
                      ) : (
                        <></>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              pageCount={Math.ceil(filteredTalleres.length / itemsPerPage)}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={({ selected }) => setCurrentPage(selected)}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header className="border-0 p-0" closeButton></Modal.Header>
        <Modal.Body className="pt-0">
          <TallerComponent taller={taller} user="admin" />

          <div className="table-responsive mt-2" style={{ height: "500px" }}>
            <table className="table table-sticky">
              <thead>
                <tr>
                  <th className="text-uppercase text-secondary bg-white text-xxs font-weight-bolder text-center">
                    Nombre
                  </th>
                  <th className="text-uppercase text-secondary bg-white text-xxs font-weight-bolder text-center">
                    Correo electronico
                  </th>
                  <th className="text-uppercase text-secondary bg-white text-xxs font-weight-bolder text-center">
                    Fecha de registro
                  </th>
                  <th className="text-uppercase text-secondary bg-white text-xxs font-weight-bolder text-center">
                    Rol de participación
                  </th>
                  <th className="text-uppercase text-secondary bg-white text-xxs font-weight-bolder text-center">
                    Tipo de participación
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm">
                {participantes.map((participante, index) => (
                  <tr key={index}>
                    <td>{participante.nombre_completo}</td>
                    <td className="text-secondary text-xs align-middle">
                      {participante.correo_electronico}
                    </td>
                    <td className="text-secondary text-xs align-middle">
                      {moment(participante.fecha_registro).format(
                        "DD MMMM YYYY h:mm:ss a"
                      )}
                    </td>
                    <td className="text-secondary text-xs align-middle text-center">
                      {participante.rol_participacion}
                    </td>
                    <td className="text-secondary text-xs align-middle text-center">
                      {participante.tipo_participacion}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Link
            to="#"
            className="text-primary fw-bolder me-2 border-0"
            onClick={handleClose}
          >
            Cerrar
          </Link>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Talleres;
