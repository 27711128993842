import Axios from "axios";
import { Link, useOutletContext } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useState } from "react";

const AdminTopBar = ({
  isSmallScreen,
  showSideBar,
  handleShowSideBar,
  context,
}) => {
  const [user, setUser] = useState(context);

  const handleLogout = (event) => {
    event.preventDefault();
    Axios.get(`${process.env.REACT_APP_API_BASE_URL}admin/auth/logout`)
      .then((res) => {
        if (res.data.Status === "Success") {
          window.location.reload();
        } else {
          alert("Hubo un problema al cerrar la sesión");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className="">
      <nav
        className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl d-flex mb-0 pb-0"
        id="navbarBlur"
        data-scroll="false"
      >
        <div className="container-fluid pt-1 w-100">
          <div className="d-flex text-right ms-auto" id="navbar">
            {/*<div className="ms-md-auto pe-md-3 d-flex align-items-center">
              <div className="input-group">
                <span className="input-group-text text-body">
                  <i className="fas fa-search" aria-hidden="true"></i>
                </span>
                <input
                  type="text"
                  className="form-control bg-white"
                  placeholder="Escribe aquí..."
                />
              </div>
            </div>*/}

            <ul className="w-100 text-right mx-0 px-0 d-flex mb-0">
              {isSmallScreen && (
                <li className="my-auto d-lg-none">
                  <Link
                    to="#"
                    className="nav-link text-white p-0 my-auto mt-2 "
                    id="iconNavbarSidenav"
                    onClick={handleShowSideBar}
                  >
                    <div className="sidenav-toggler-inner">
                      <i className="sidenav-toggler-line bg-white"></i>
                      <i className="sidenav-toggler-line bg-white"></i>
                      <i className="sidenav-toggler-line bg-white"></i>
                    </div>
                  </Link>
                </li>
              )}
              <Dropdown className="mb-0">
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="mb-0"
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    paddingRight: 0,
                  }}
                >
                  <div className="media align-items-center d-flex">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="Image placeholder"
                        src="https://static-cdn.jtvnw.net/user-default-pictures-uv/998f01ae-def8-11e9-b95c-784f43822e80-profile_image-70x70.png"
                      />
                    </span>
                    <div className="media-body ms-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold text-white">
                        {user.nombre}
                      </span>
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Header className="d-none">
                    ¡Bienvenido!
                  </Dropdown.Header>
                  <Dropdown.Divider className="d-none" />
                  <Dropdown.Item href="#" className="d-none">
                    <i class="fas fa-user me-2"></i>
                    <span>Mi perfil</span>
                  </Dropdown.Item>
                  <Dropdown.Item href="#" className="d-none">
                    <i class="fas fa-cog me-2"></i>
                    <span>Configuración</span>
                  </Dropdown.Item>
                  <Dropdown.Item href="#" className="d-none">
                    <i class="fas fa-calendar-alt me-2"></i>
                    <span>Actividades</span>
                  </Dropdown.Item>
                  <Dropdown.Item href="#" className="d-none">
                    <i class="fas fa-life-ring me-2"></i>
                    <span>Ayuda</span>
                  </Dropdown.Item>
                  <Dropdown.Item href="#" onClick={handleLogout}>
                    <i class="fas fa-running me-2"></i>
                    <span>Salir</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              {/*<li className="nav-item px-3 d-flex align-items-center">
                <a href="" className="nav-link text-white p-0">
                  <i
                    className="fa fa-cog fixed-plugin-button-nav cursor-pointer"
                    aria-hidden="true"
                  ></i>
                </a>
              </li>

              <li className="nav-item dropdown pe-2 d-flex align-items-center">
                <a
                  href=""
                  className="nav-link text-white p-0"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i
                    className="fa fa-bell cursor-pointer"
                    aria-hidden="true"
                  ></i>
                </a>
                <ul
                  className="dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4"
                  aria-labelledby="dropdownMenuButton"
                >
                  <li className="mb-2">
                    <a className="dropdown-item border-radius-md" href="">
                      <div className="d-flex py-1">
                        <div className="my-auto">
                          <img
                            src="../assets/img/team-2.jpg"
                            className="avatar avatar-sm  me-3 "
                          />
                        </div>
                        <div className="d-flex flex-column justify-content-center">
                          <h6 className="text-sm font-weight-normal mb-1 text-white">
                            <span className="font-weight-bold">
                              New message
                            </span>{" "}
                            from Laur
                          </h6>
                          <p className="text-xs text-secondary mb-0">
                            <i
                              className="fa fa-clock me-1"
                              aria-hidden="true"
                            ></i>
                            13 minutes ago
                          </p>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="mb-2">
                    <a className="dropdown-item border-radius-md" href="">
                      <div className="d-flex py-1">
                        <div className="my-auto">
                          <img
                            src="../assets/img/small-logos/logo-spotify.svg"
                            className="avatar avatar-sm bg-gradient-dark  me-3 "
                          />
                        </div>
                        <div className="d-flex flex-column justify-content-center">
                          <h6 className="text-sm font-weight-normal mb-1 text-white">
                            <span className="font-weight-bold">New album</span>{" "}
                            by Travis Scott
                          </h6>
                          <p className="text-xs text-secondary mb-0">
                            <i
                              className="fa fa-clock me-1"
                              aria-hidden="true"
                            ></i>
                            1 day
                          </p>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item border-radius-md" href="">
                      <div className="d-flex py-1">
                        <div className="avatar avatar-sm bg-gradient-secondary  me-3  my-auto"></div>
                        <div className="d-flex flex-column justify-content-center">
                          <h6 className="text-sm font-weight-normal mb-1 text-white">
                            Payment successfully completed
                          </h6>
                          <p className="text-xs text-secondary mb-0">
                            <i
                              className="fa fa-clock me-1"
                              aria-hidden="true"
                            ></i>
                            2 days
                          </p>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </li>*/}
              <li className="nav-item d-flex align-items-center ms-4 d-none">
                <Link
                  to="#"
                  className="nav-link text-white font-weight-bold px-0 mb-0"
                  onClick={handleLogout}
                >
                  <i className="fa-solid fa-right-from-bracket me-2"></i>
                  <span className="d-sm-inline d-none">Salir</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default AdminTopBar;
