import gif from "../../assets/images/work-in-progress.gif";
const Reportes = () => {
  return (
    <div style={{ position: "relative", zIndex: "1" }}>
      <h4 className="text-white ms-4">Reportes</h4>
      <div className="container-fluid py-4">
        <div className="card">
          <div className="card-bod text-center">
            <img src={gif} className="navbar-brand-img h-100" alt="main_logo" />
            <h1 className="mt-1 mb-5">Trabajo en progreso...</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reportes;
