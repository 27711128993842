import React from "react";
import imageLayout from "../../assets/images/eventos-header.webp";

const WelcomeHeader = (props) => (
  <div
    className="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg"
    style={{
      backgroundImage: `url(${imageLayout})`,
      backgroundPosition: "center",
    }}
  >
    <span className="mask bg-gradient-dark opacity-6"></span>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-5 text-center mx-auto">
          <h1 className="text-white mb-2 mt-5">{props.title}</h1>
          <p className="text-lead text-white">{props.subtitle}</p>
        </div>
      </div>
    </div>
  </div>
);

export default WelcomeHeader;
