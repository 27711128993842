import Axios from "axios";
import { useState } from "react";
import Swal from "sweetalert2";
import WelcomeHeader from "../../../components/components-common/WelcomeHeader";

const ForgotPassword = () => {
  const [correoElectronico, setCorreoEletronico] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      Axios.defaults.withCredentials = true;
      await Axios.post(`${process.env.REACT_APP_API_BASE_URL}ForgotPassword`, {
        correo_electronico: correoElectronico,
      }).then((response) => {
        if (response.data) {
          Swal.fire({
            icon: "success",
            title: "¡Correo enviado con éxito!",
            text: "Verifica tu correo, te daremos instrucciones para obtener una nueva contraseña",
            showConfirmButton: false,
            timer: 8000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Algo salió mal en el envío",
            footer: "<p>Favor de notificar e intentar más tarde. Gracias</p>",
          });
        }
      });
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Algo salió mal en el envío",
        footer: "<p>Favor de notificar e intentar más tarde. Gracias</p>",
      });
    }
  };

  return (
    <main className="main-content mt-0 ps body-fade">
      <WelcomeHeader
        title="¡Bienvenidos Innova Forum 2024!"
        subtitle="Estamos encantados de que nos acompañes a este gran evento."
      />
      <div className="container mt-2 mb-5">
        <div className="row mt-lg-n11 mt-md-n11 mt-n11 justify-content-center">
          <div className="col-xl-4 col-lg-5 col-md-7 mx-auto">
            <div className="card z-index-0">
              <div className="card-header text-center pt-4 pb-0">
                <h5>Recuperar contraseña</h5>
              </div>

              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Correo electrónico"
                      aria-label="Email"
                      onChange={(event) =>
                        setCorreoEletronico(event.target.value)
                      }
                    />
                  </div>
                  <div className="mt-3 mb-2">
                    <p className="text-sm">
                      Te enviaremos un correo electrónico a esta dirección con
                      las instrucciones.
                    </p>
                  </div>
                  <div className="text-center mt-2">
                    <button
                      type="submit"
                      className="btn btn-primary bg-gradient-primary w-100 my-4 mb-2"
                    >
                      Enviar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ForgotPassword;
