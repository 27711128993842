const Perfil = () => {
  return (
    <div style={{ position: "relative", zIndex: "1" }}>
      <h4 className="text-white ms-4">Perfil</h4>
      <div className="container-fluid py-4"></div>
    </div>
  );
};

export default Perfil;
